<template>
  <div class="engineering-list-display">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="tenderGuid" placeholder="标段" clearable>
            <el-option
              v-for="tender in tenders"
              :key="tender.tenderGuid"
              :label="tender.tenderName"
              :value="tender.tenderGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      :data="engineeringList"
      border
      default-expand-all
      :height="tableHeight"
      row-key="engineeringListGuid"
      header-cell-class-name="bg-info text-light"
      >
      <el-table-column
        type="index"
        width="50"
        header-align="center"
        align="center"
        label="序号"
        >
      </el-table-column>
      <el-table-column
        prop="engineeringListId"
        label="ID"
        width="300"
        >
      </el-table-column>
      <el-table-column
        prop="engineeringListCode"
        label="形象进度编号"
        width="300"
        >
      </el-table-column>
      <el-table-column
        prop="engineeringListName"
        label="形象进度名称"
        width="600"
        >
      </el-table-column>
      <el-table-column
        prop="unit"
        label="单位"
        header-align="center"
        align="center"
        width="150"
        >
      </el-table-column>
      <el-table-column
        prop="num"
        label="数量"
        header-align="center"
        align="center"
        :formatter="numberFormatter"
        >
      </el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <engineering-list-form
        ref="engineeringListForm"
        :engineeringListModel="engineeringListModel">
      </engineering-list-form>
    </my-dialog>
  </div>
</template>

<script>
import tenderService from '@/services/tenderService'
import engineeringListService from '@/services/engineeringListService'
import EngineeringListModel from '@/model/EngineeringListModel'
import EngineeringListForm from '@/views/EngineeringListManage/EngineeringListForm'
import MyDialog from '@/components/MyDialog/MyDialog'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import PageOperation from '@/mixins/PageOperation'

export default {
  name: 'EngineeringListDisplay',
  mixins: [
    PageOperation
  ],
  components: {
    EngineeringListForm,
    MyDialog,
    MySearchBar
  },
  data () {
    return {
      tenders: [],
      tenderGuid: '',
      engineeringList: [],
      formDialogTitle: '',
      engineeringListModel: null
    }
  },
  watch: {
    tenderGuid (newVal) {
      newVal && this._getEngineeringList(newVal)
    }
  },
  methods: {
    handleAddClick () {
      this.formDialogTitle = '添加形象进度清单信息'
      this.engineeringListModel = new EngineeringListModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑形象进度清单信息'
      this.engineeringListModel = new EngineeringListModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _engineeringListModel = new EngineeringListModel(row)
          engineeringListService.delete(_engineeringListModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getEngineeringList(this.tenderGuid)
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.engineeringListForm.validate()(valid => {
        if (valid) {
          if (!this.engineeringListModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    numberFormatter (row, column) {
      return row[column.property] === 0 ? '' : row[column.property]
    },
    diffFormatter (row) {
      let diff = row.amount - Math.round(row.num * row.price)
      return row.amount ? diff : ''
    },
    _getEngineeringList (tenderGuid) {
      engineeringListService.treeList(tenderGuid)
        .then(res => {
          console.log(res.data)
          this.engineeringList = res.data.data
          this.$message({
            type: 'success',
            message: '列表加载成功！'
          })
        })
        .catch(err => {
          console.log(err.request.response)
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          window.concole.log(err.request.response)
        })
    },
    _save () {
      this.engineeringListModel.num = this.engineeringListModel.num.toString()
      engineeringListService.add(this.engineeringListModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getEngineeringList(this.tenderGuid)
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      this.engineeringListModel.num = this.engineeringListModel.num.toString()
      engineeringListService.edit(this.engineeringListModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getEngineeringList(this.tenderGuid)
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err)
        })
    }
  },
  created () {
    this._getTenders()
  }

}
</script>

<style scoped lang="scss">
.engineering-list-display {
  width: 100%;
  height: 100%;
}
</style>
