import utility from '@/common/utility'
import auth from '@/common/auth'

export default class EngineeringListModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.engineeringListGuid
      this.engineeringListGuid = props.engineeringListGuid
      this.engineeringListId = props.engineeringListId
      this.parentId = props.parentId
      this.engineeringListCode = props.engineeringListCode
      this.engineeringListName = props.engineeringListName
      this.unit = props.unit
      this.num = props.num
      this.tenderGuid = props.tenderGuid
      this.sortId = props.sortId
      this.children = props.children
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.engineeringListGuid) this.engineeringListGuid = ''
    if (!this.engineeringListId) this.engineeringListId = 0
    if (!this.parentId) this.parentId = 0
    if (!this.engineeringListCode) this.engineeringListCode = ''
    if (!this.engineeringListName) this.engineeringListName = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = ''
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.sortId) this.sortId = 0
    if (!this.children) this.children = []
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.engineeringListGuid = utility.getUuid()
    this.resourceId = this.engineeringListGuid
  }
}
