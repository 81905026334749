<template>
  <div class="div">
    <input type="text" readonly class="form-control" :value="displayValue" @click="treeShow = !treeShow">
    <el-tree v-show="treeShow"
             class="mt-1"
             :node-key="nodeKey"
             ref="elTree"
             show-checkbox
             :check-strictly="true"
             :data="nodes"
             @check="handleCheck"
             :props="props">
    </el-tree>
  </div>
</template>

<script>
export default {
  name: 'MySelectTree',
  props: {
    nodes: {
      type: Array,
      default () {
        return []
      }
    },
    nodeKey: {
      type: String,
      default: ''
    },
    labelKey: {
      type: String,
      defautl: ''
    },
    props: {
      type: Object,
      defautl () {
        return {
          label: 'name',
          children: 'children'
        }
      }
    },
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      treeShow: false,
      displayValue: ''
    }
  },
  methods: {
    handleCheck (node) {
      this.$refs.elTree.setCheckedKeys([node[this.nodeKey]])
      this.$emit('input', node[this.nodeKey])
    }
  },
  watch: {
    value (newVal) {
      if (this.value) {
        this.displayValue = this.$refs.elTree.getNode(this.value).data[this.labelKey]
      }
    }
  },
  mounted () {
    if (this.value) {
      this.displayValue = this.$refs.elTree.getNode(this.value).data[this.labelKey]
    }
  }
}
</script>

<style scoped lang="scss">
input {
  cursor: pointer;
}
::v-deep .el-checkbox__input {
  padding-top: 10px;
}
</style>
